import { Asset } from '@/store/models/asset';
import { firebase } from '@/firebase';

/**
 * Returns a fake asset object used for hiding details for premium funds
 * @param {string} loc - The current locale
 */
export const premiumAssetTemplate = (loc: string = 'nl'): Omit<Asset, 'name'| 'city'| 'street'| 'country'| 'id'| 'startDateTime' | 'images'> => {
  const premiumText = loc === 'nl' ? 'Dit is een premium fonds, log in om het te zien.' : 'This is a premium fund, please log in to see it.';

  return {
    published: true,
    houseNumber: '',
    postalCode: '',
    investmentCase: premiumText,
    propertyDetails: premiumText,
    euroMin: 0,
    totalValueEuro: 0,
    totalValueShares: 100,
    totalDividendAmount: 10,
    sharesAvailable: 100,
    sharePrice: 0,
    emissionCost: 0,
    deleted: false,
    premium: true,
    brochure: [],
    dividendsFormat: [{ contents: ['3', 7.9] }, { contents: ['5', 10.8] }],
    createdDateTime: new firebase.firestore.Timestamp(23234, 0),
    endDateTime: new firebase.firestore.Timestamp(23234, 0),
    returnsAfterEnd: 0,
    floorPlanImages: [],
    prospectus: [],
    updatedDateTime: new firebase.firestore.Timestamp(23234, 0),
  };
};
